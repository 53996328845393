<template>
  <div
    class="pointer-events-none sticky bottom-4 flex justify-center pt-8 lg:hidden"
  >
    <div
      class="pointer-events-auto flex gap-1 rounded-xl bg-blue-200 px-4 py-2 shadow-lg dark:bg-background-darken dark:ring-1 dark:ring-zinc-600 sm:gap-4"
    >
      <LayoutMenu />
    </div>
  </div>
</template>
